<template>
  <b-container fluid class="py-5">
    <b-card>
      <b-row>
        <b-col class="text-center">
          <strong>Valide sus datos personales</strong>
        </b-col>
      </b-row>
      <b-form class="mt-4">
        <b-form-group
          id="fullName"
          label="Nombre Completo de la empresa"
          label-for="fullName"
        >
          <b-form-input
            id="fullName"
            type="text"
            placeholder="Empresa"
            v-model="$v.form.data.fullName.$model"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="dni" label="CUIT" label-for="dni">
          <b-form-input
            no-wheel
            id="dni"
            type="number"
            placeholder="XXXXXXXX"
            v-model="$v.form.data.dni.$model"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="actividad" label="Actividad principal" label-for="actividad">
          <b-form-input
            no-wheel
            id="actividad"
            type="text"
            placeholder="escriba su activdad principal"
            v-model="$v.form.data.actividad.$model"
            required
          ></b-form-input>
        </b-form-group>        
        <b-form-group
          id="phoneNumber"
          label="Numero de telefono"
          label-for="phoneNumber"
        >
          <b-input-group prepend="+54">
            <b-form-input
              id="phoneNumber"
              type="number"
              no-wheel
              placeholder="XX XXXX XXXX"
              v-model="$v.form.data.phoneNumber.$model"
              required
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-button
          class="align-items-center button-shadow"
          block
          v-b-tooltip.hover
          title="Siguiente"
          @click.prevent="confirmStep"
          variant="primary"
          :disabled="
            !$v.$anyDirty || $v.$anyError || this.loading || emptyField
          "
        >
          <span v-if="!this.loading" class="mb-2  form-text">SIGUIENTE</span>
          <b-spinner type="grow" v-else label="Loading..."></b-spinner>
        </b-button>
      </b-form>
      <b-row class="pt-2 mt-2">
        <stepper class="stepper" :filled="currentStep" :size="maxSteps"/>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import stepsMixin from '@/mixins/stepsMixin'

export default {
  name: "UploadCompanyData",
  components: {
    Stepper: () => import('@/views/Components/Giftcards/Stepper')
  },
  mixins: [stepsMixin],
  data() {
    return {
      form: {
        data: { fullName: "", dni: "", phoneNumber: "", actividad: "" },
      },
      loading: false,
      clicked: false,
    }
  },
  computed: {
    emptyField: function () {
      return (
        this.form.data.fullName === "" ||
        this.form.data.dni === "" ||
        this.form.data.phoneNumber === "" ||
        this.form.data.actividad === ""
      )
    },
  },
  methods: {
    confirmStep() {
      this.form.data.phoneNumber = "+5411".concat(String(this.form.data.phoneNumber))
      this.$emit("confirm-step", this.form)
    },
  },
  validations: {
    form: {
      data: {
        fullName: {
          required,
        },
        dni: {
          required,
        },
        actividad: {
          required,
        },
        phoneNumber: {
          required,
        },
      },
    },
  },
}
</script>

<style>
.unclickedPicture {
  background-color: rgba(0, 0, 0, 0.233);
}
.clickedPicture {
  background-color: rgba(0, 0, 0, 0.877);
}

.unclickedCard {
  color: rgba(0, 0, 0, 0.384);
}
.clickedCard {
  color: rgba(0, 0, 0, 0.877);
  border: 2px solid #352f77;
}
</style>
